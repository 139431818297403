import { base64Decode, getCookie } from "common/utils/cookieUtils";
import { axiosInstance } from "config/axiosConfig";
import type { SignInRequest } from "types/auth";

export async function getMetaxInfo(corpNo: number) {
  const response = await axiosInstance.post("/user/getListMetaxInfo", {
    corpNo: corpNo,
    custTypCd: "P",
  });
  return response.data;
}

export async function signIn(signInRequest: SignInRequest) {
  //TODO: 나중에 토큰 && 비밀번호만으로 검증(로그인 x)하는 API 생길 시 분리해야함.
  const base64String = getCookie("scm_at")?.split(".")[1];
  let requestBody = signInRequest;
  if (base64String) {
    const user = base64Decode(base64String);
    const userId = user.USER_INFO.userId;
    const custTypCd = user.USER_INFO.custTypCd;
    requestBody = { ...requestBody, userId, custTypCd };
  }
  // const response = await axiosInstance.post("/user/signIn", signInRequest);
  const response = await axiosInstance.post("/user/signInNew", signInRequest);
  return response.data;
}

export async function signOut() {
  const response = await axiosInstance.post("/api/user/signOut", {
    withCredentials: true,
  });
  return response.data;
}

export async function backgroundRefreshToken() {
  const response = await axiosInstance.post(
    "/api/user/refreshToken?key=scm_rt",
    null,
    {
      baseURL: process.env.REACT_APP_BASE_URL ?? "/",
      withCredentials: true,
    },
  );
  return response.data;
}

// 최초 로그인 시 출판사 정보 업데이트
export async function updateFirstPublisherInfo(reqVo: Record<string, unknown>) {
  const response = await axiosInstance.post("/user/pubInsReqVo", {
    userId: reqVo.userId,
    secretKey: reqVo.secretKey,
    pdept_nm: reqVo.pdept_nm,
    ptel_no: reqVo.ptel_no,
    pe_mail: reqVo.pe_mail,
    userFrstNo: reqVo.userFrstNo,
    userScndNo: reqVo.userScndNo,
  });
  return response.data;
}

// 비밀번호 재발급
export async function passwordReset(reqVo: Record<string, unknown>) {
  const response = await axiosInstance.post("user/setInitSecretKey", reqVo);
  return response.data;
}
