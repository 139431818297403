import { createStore } from "@methodjs/store";
import type { UserInfo } from "types/user";

interface UserState {
  isSignedIn: boolean;
  user: UserInfo;
}

export const initUserState = (): UserState => ({
  isSignedIn: false,
  user: {
    modSecretKey: "",
    userName: "",
    branchCode: "",
    headCode: "",
    initSecretKeyYn: "",
    ipgeum_yn: "", // 입금증 출력여부
    guild_yn: "", // 기본조합비 출력여부
    manage_yn: "", // 관리조합비 출력여부
    gong_yn: "", // 공급조합비 출력여부
  },
});

const [useUserStore, setUserStore, getUserStore] = createStore(initUserState);

export { useUserStore, setUserStore, getUserStore };
