import LoadingSpinner from "common/components/LoadingSpinner";
import { URLs } from "common/constants";
import PageContainer from "common/components/PageContainer";
import SignedInContainer from "common/components/PageContainer/SignedInContainer";
import SignedOutContainer from "common/components/PageContainer/SignedOutContainer";
import React, {
  Suspense,
  lazy,
  LazyExoticComponent,
  MemoExoticComponent,
} from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import type { RouteCondition } from "types/urls";
import type { URLPaths } from "types/urls";
import { initialMenuState } from "common/constants/initialMenuState";
import WheneverContainer from "common/components/PageContainer/WheneverContainer";

interface RouteType {
  path: URLPaths;
  component:
    | LazyExoticComponent<() => JSX.Element>
    | LazyExoticComponent<MemoExoticComponent<() => JSX.Element>>;
  requireHeader: boolean;
  routeCondition: RouteCondition;
  cssId: string;
  title: string;
  description?: () => JSX.Element;
}

// 코드 스플리팅을 위한 lazy import
const Main = lazy(() => import("pages/Main"));
const SignIn = lazy(() => import("pages/SignIn"));
const MyPage = lazy(() => import("pages/MyPage"));
const SalesHistory = lazy(
  () => import("pages/Analysis/SalesHistory/SalesHistory"),
);
const NewBook = lazy(() => import("pages/Books/NewBook/NewBook"));
const RefundRequest = lazy(
  () => import("pages/Books/RefundRequest/RefundRequest"),
);
const BookManagement = lazy(
  () => import("pages/Books/BookManagement/BookManagement"),
);
const Bills = lazy(() => import("pages/Deals/Bills"));
const DealsLedger = lazy(() => import("pages/Deals/DealsLedger"));
const PaymentHistory = lazy(() => import("pages/Deals/PaymentHistory"));
const Forwarding = lazy(() => import("pages/Orders/Forwarding"));
const Incoming = lazy(() => import("pages/Orders/Incoming"));
const Order = lazy(() => import("pages/Orders/Order"));
const Outcoming = lazy(() => import("pages/Orders/Outcoming"));
const Refund = lazy(() => import("pages/Orders/Refund"));
const ManagerInfo = lazy(() => import("pages/Support/ManagerInfo"));
const Notice = lazy(() => import("pages/Support/Notice"));
const NoticeDetail = lazy(() => import("pages/Support/NoticeDetail"));
const Reference = lazy(() => import("pages/Support/Reference"));
const ReferenceDetail = lazy(() => import("pages/Support/ReferenceDetail"));
const ManuscriptSubmitStatus = lazy(
  () => import("pages/Support/ManuscriptSubmitStatus"),
);
const ManuscriptSubmitStatusDetail = lazy(
  () => import("pages/Support/ManuscriptSubmitStatusDetail"),
);
const InvalidApproach = lazy(() => import("pages/InvalidApproach"));

export const routes: RouteType[] = [
  {
    path: URLs.Main,
    component: Main,
    requireHeader: true,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "main-page",
    title: "출판협동조합",
  },
  {
    path: URLs.SignIn,
    component: SignIn,
    requireHeader: false,
    routeCondition: "MUST_BE_SIGNED_OUT",
    cssId: "sign-in-page",
    title: "로그인",
  },
  {
    path: URLs.MyPage,
    component: MyPage,
    requireHeader: true,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "my-page",
    title: "마이페이지",
  },
  {
    path: URLs.NewBook,
    component: NewBook,
    requireHeader: true,
    routeCondition: "WHENEVER",
    cssId: "new-book",
    title: "신간 등록",
    // description: () => (
    //   <div className="description">
    //     고객에게 제공되는 <span>도서의 정보를 등록, 관리하는 메뉴</span>입니다.
    //   </div>
    // ),
  },
  {
    path: URLs.BookManagement,
    component: BookManagement,
    requireHeader: true,
    routeCondition: "WHENEVER",
    cssId: "book-management",
    title: "도서 정보 관리",
  },

  {
    path: URLs.RefundRequest,
    component: RefundRequest,
    requireHeader: true,
    routeCondition: "WHENEVER",
    cssId: "refund-request",
    title: "반출 요청",
  },
  {
    path: URLs.Order,
    component: Order,
    requireHeader: true,
    routeCondition: "WHENEVER",
    cssId: "order",
    title: "발주 관리",
  },
  {
    path: URLs.Incoming,
    component: Incoming,
    requireHeader: true,
    routeCondition: "WHENEVER",
    cssId: "incoming",
    title: "입고 관리",
  },
  {
    path: URLs.Outcoming,
    component: Outcoming,
    requireHeader: true,
    routeCondition: "WHENEVER",
    cssId: "outcoming",
    title: "출고 관리",
  },
  {
    path: URLs.Refund,
    component: Refund,
    requireHeader: true,
    routeCondition: "WHENEVER",
    cssId: "refund",
    title: "반품 관리",
  },
  {
    path: URLs.Forwarding,
    component: Forwarding,
    requireHeader: true,
    routeCondition: "WHENEVER",
    cssId: "forwarding",
    title: "회송 관리",
  },
  {
    path: URLs.DealsLedger,
    component: DealsLedger,
    requireHeader: true,
    routeCondition: "WHENEVER",
    cssId: "deals-ledger",
    title: "거래원장 조회",
  },
  {
    path: URLs.PaymentHistory,
    component: PaymentHistory,
    requireHeader: true,
    routeCondition: "WHENEVER",
    cssId: "payment-history",
    title: "지불내역 조회",
  },
  {
    path: URLs.Bills,
    component: Bills,
    requireHeader: true,
    routeCondition: "WHENEVER",
    cssId: "bills",
    title: "계산서 조회",
  },
  {
    path: URLs.SalesHistory,
    component: SalesHistory,
    requireHeader: true,
    routeCondition: "WHENEVER",
    cssId: "sales-history",
    title: "매출 현황",
  },
  {
    path: URLs.Notice,
    component: Notice,
    requireHeader: true,
    routeCondition: "WHENEVER",
    cssId: "notice",
    title: "공지사항",
  },
  {
    path: URLs.NoticeDetail,
    component: NoticeDetail,
    requireHeader: true,
    routeCondition: "WHENEVER",
    cssId: "notice-detail",
    title: "공지사항",
  },
  {
    path: URLs.ManagerInfo,
    component: ManagerInfo,
    requireHeader: true,
    routeCondition: "WHENEVER",
    cssId: "manager-info",
    title: "조합 담당자 정보",
  },
  {
    path: URLs.ManuscriptSubmitStatus,
    component: ManuscriptSubmitStatus,
    requireHeader: true,
    routeCondition: "WHENEVER",
    cssId: "manuscript-submit-status",
    title: "원고투고현황",
  },
  {
    path: URLs.ManuscriptSubmitStatusDetail,
    component: ManuscriptSubmitStatusDetail,
    requireHeader: true,
    routeCondition: "WHENEVER",
    cssId: "manuscript-submit-status-detail",
    title: "원고투고현황",
  },
  {
    path: URLs.Reference,
    component: Reference,
    requireHeader: true,
    routeCondition: "WHENEVER",
    cssId: "reference",
    title: "자료실",
  },
  {
    path: URLs.ReferenceDetail,
    component: ReferenceDetail,
    requireHeader: true,
    routeCondition: "WHENEVER",
    cssId: "reference-detail",
    title: "자료실",
  },
];

const PageRoutes = () => {
  return (
    <Router>
      <Suspense fallback={<LoadingSpinner />}>
        <Routes>
          {routes.map((route, index) => {
            const {
              path,
              component: Component,
              routeCondition,
              cssId: id,
              title,
              description,
            } = route;
            let menuIdx = -1;
            initialMenuState.forEach((menu, idx) => {
              menu.childMenus?.forEach((child) => {
                if (child.title === title) {
                  menuIdx = idx;
                }
              });
            });
            const parentMenu =
              menuIdx !== -1 ? initialMenuState[menuIdx].title : null;

            switch (routeCondition) {
              case "WHENEVER":
                return (
                  <Route
                    key={index}
                    path={path}
                    element={
                      <WheneverContainer>
                        <PageContainer
                          id={id}
                          title={title}
                          parentMenu={parentMenu}
                          description={description}
                        >
                          <Component />
                        </PageContainer>
                      </WheneverContainer>
                    }
                  />
                );
              case "MUST_BE_SIGNED_IN":
                return (
                  <Route
                    key={index}
                    path={path}
                    element={
                      <SignedInContainer>
                        <PageContainer
                          id={id}
                          title={title}
                          parentMenu={parentMenu}
                          description={description}
                        >
                          <Component />
                        </PageContainer>
                      </SignedInContainer>
                    }
                  />
                );
              case "MUST_BE_SIGNED_OUT":
                return (
                  <Route
                    key={index}
                    path={path}
                    element={
                      <SignedOutContainer>
                        <PageContainer
                          id={id}
                          title={title}
                          parentMenu={parentMenu}
                          description={description}
                        >
                          <Component />
                        </PageContainer>
                      </SignedOutContainer>
                    }
                  />
                );
            }
          })}
          <Route path="*" element={<InvalidApproach />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default PageRoutes;
