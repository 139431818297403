const URLs = {
  Main: "/",
  SignIn: "/sign-in",
  Test: "/test",
  MyPage: "/my-page",
  SalesHistory: "/sales-history",
  NewBook: "/book/new",
  RefundRequest: "/book/refund-request",
  BookManagement: "/book/book-management",
  Bills: "/bills",
  DealsLedger: "/deals-ledger",
  PaymentHistory: "/payment-history",
  Forwarding: "/orders/forwarding",
  Incoming: "/orders/incoming",
  Order: "/orders",
  Outcoming: "/orders/outcoming",
  Refund: "/orders/refund",
  ManagerInfo: "/support/manager-info",
  Notice: "/support/notice",
  NoticeDetail: "/support/notice-detail",
  Reference: "/support/reference",
  ReferenceDetail: "/support/reference-detail",
  ManuscriptSubmitStatus: "/support/manuscript-submit-status",
  ManuscriptSubmitStatusDetail: "/support/manuscript-submit-status-detail",
} as const;

export default URLs;
