import { useMutation, useQuery } from "@tanstack/react-query";
import {
  backgroundRefreshToken,
  getMetaxInfo,
  passwordReset,
  signIn,
  signOut,
  updateFirstPublisherInfo,
} from "api/authApi";
import { useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import { useModal, useNavigation, useUser } from "common/hooks";
import { axiosInstance } from "config/axiosConfig";
import type { APIErrorResponse, APIResponseData } from "types/api";
import type { UserInfo, UserResponse } from "types/user";
import type { URLPaths } from "types/urls";
import { deleteCookie, getCookie } from "common/utils/cookieUtils";
import queryKey from "common/constants/queryKey";
import { getUserInfo } from "api/userApi";
import usePreMutation from "common/hooks/usePreMutation";

export const useGetMetaxInfoMutation = () => {
  const mutation = useMutation(getMetaxInfo);
  return { ...mutation };
};

export const useSignInQuery = () => {
  const [resultCd, setResultCd] = useState(Number);
  const [resData, setResData] = useState<Record<string, any>>();
  const [errorMessage, setErrorMessage] = useState("");
  const { navigate } = useNavigation();
  const { search } = useLocation();
  const { setUser } = useUser();

  const mutation = useMutation(signIn, {
    useErrorBoundary: false,
    onError: (error: APIErrorResponse) => {
      if (error.status === 500) {
        setErrorMessage("서버 오류입니다.");
      } else {
        setErrorMessage(error?.data?.resultMsg);
      }
    },
    retry: 0,
    onSuccess: (res: APIResponseData<UserResponse>) => {
      if (res.resultCd === 201) {
        // 최초 로그인 여부 확인
        if (res.data) {
          setResultCd(res.resultCd);
          setResData(res.data);
        }
      } else {
        if (res.data) {
          // alert(`${res.data.NICK_NAME}님, 환영합니다.`);
          setUser({
            userName: res.data.NICK_NAME,
            modSecretKey: res.data.MOD_SECRET_KEY,
            branchCode: res.data.USER_ID,
            headCode: res.data.tax_no,
            initSecretKeyYn: res.data.initSecretKeyYn,
            ipgeum_yn: res.data.ipgeum_yn, // 입금증 출력여부
            guild_yn: res.data.guild_yn, // 기본조합비 출력여부
            manage_yn: res.data.manage_yn, // 관리조합비 출력여부
            gong_yn: res.data.gong_yn, // 공급조합비 출력여부
          });
          if (res.resultCd === 202) {
            navigate(`/my-page` as URLPaths);
          } else {
            navigate(search.substring(10) as URLPaths);
          }
        }
        const newAccessToken = getCookie("scm_at");
        axiosInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${newAccessToken}`;
      }
    },
  });

  const handleSignIn = useCallback(
    (corpNo: number, metaxNo: string, secretKey: string) => {
      setErrorMessage("");
      if (!(metaxNo && corpNo && secretKey)) {
        setErrorMessage("아이디와 비밀번호를 입력해주세요.");
        return;
      } else {
        mutation.mutateAsync({
          corpNo: `${corpNo}`,
          userId: metaxNo,
          secretKey,
        });
      }
    },
    [],
  );
  return {
    ...mutation,
    errorMessage,
    resultCd,
    resData,
    handleSignIn,
  };
};

export const useSignOutQuery = () => {
  const { preMutation } = usePreMutation();
  const { mutateAsync } = useMutation(signOut, {
    useErrorBoundary: true,
    onSuccess: () => {
      console.log("signOut success");
      deleteCookie("scm_at");
      location.href = "/sign-in";
    },
    onError: (error: APIErrorResponse) => {
      alert(error.data.resultMsg);
      console.log("signOut error");
      deleteCookie("scm_at");
      location.href = "/sign-in";
    },
  });
  const querySignOut = async () => {
    await preMutation();
    await mutateAsync();
  };
  return { querySignOut };
};

//TODO: api 함수 signIn에서 changePassword로 변경
export const useUserValidateQuery = () => {
  const [isUserValid, setIsUserValid] = useState(false);
  const [userValidMessage, setUserValidMessage] = useState("");

  const { mutateAsync: validateUser } = useMutation(signIn, {
    useErrorBoundary: false,
    onError: (error: APIErrorResponse) => {
      setUserValidMessage(error.data.resultMsg);
    },
    onSuccess: (res: APIResponseData<UserInfo>) => {
      if (res.resultCd === 200) {
        setIsUserValid(true);
        setUserValidMessage("비밀번호가 일치합니다.");
      } else {
        setUserValidMessage("현재 비밀번호를 맞게 입력해주세요.");
      }
    },
  });

  return { isUserValid, userValidMessage, validateUser };
};

export const useBackgroundRefreshTokenQuery = () => {
  const { setUser } = useUser();
  const { refetch } = useQuery([queryKey.USER], getUserInfo, {
    enabled: false,
    onSuccess: (res: APIResponseData<UserResponse>) => {
      if (res.data) {
        setUser({
          modSecretKey: res.data.MOD_SECRET_KEY,
          userName: res.data.NICK_NAME,
          branchCode: res.data.USER_ID,
          headCode: res.data.tax_no,
          taxNo: res.data.tax_no,
          initSecretKeyYn: res.data.initSecretKeyYn,
          ipgeum_yn: res.data.ipgeum_yn, // 입금증 출력여부
          guild_yn: res.data.guild_yn, // 기본조합비 출력여부
          manage_yn: res.data.manage_yn, // 관리조합비 출력여부
          gong_yn: res.data.gong_yn, // 공급조합비 출력여부
        });
      }
    },
    useErrorBoundary: false,
  });

  const mutation = useMutation(backgroundRefreshToken, {
    onSuccess: () => {
      refetch();
    },
  });
  return { ...mutation };
};

// 최초 로그인 시 출판사 정보 업데이트
export const usePublisherInfoUpdateQuery = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const { modal } = useModal();

  const mutation = useMutation(updateFirstPublisherInfo, {
    useErrorBoundary: false,
    onError: (error: APIErrorResponse | any) => {
      console.log(error);
      if (error.code === "ERR_NETWORK") {
        setErrorMessage("서버 오류로 서비스를 사용할 수 없습니다.");
      } else {
        if (error.status === 500) {
          setErrorMessage("서버 오류입니다.");
        } else {
          setErrorMessage(error?.data?.resultMsg);
          alert("서비스 오류입니다. 잠시 후에 다시 시도해주세요.");
        }
      }
    },

    onSuccess: (res: APIResponseData<UserResponse>) => {
      // 정상 저장
      alert(
        "정보가 정상적으로 저장되었습니다. 변경된 비밀번호로 로그인해주세요",
      );
    },
  });

  const handleSaveInfo = useCallback(
    (publisherInfo: Record<string, unknown>) => {
      setErrorMessage("");
      if (!publisherInfo) {
        setErrorMessage("정보를 입력해주세요");
        return;
      } else {
        if (
          window.confirm(
            "해당 정보는 조합과의 거래 정보 관리 및 주문배송 알림 수신에 필요한 정보입니다.\n작성 오류로 인하여 불이익이 발생할 수 있으니 정확한 정보를 기입해주시기 바랍니다.",
          )
        ) {
          mutation.mutateAsync({
            ...publisherInfo,
          });
          modal.close();
        } else {
          alert("취소되었습니다.");
        }
      }
    },
    [],
  );

  return {
    ...mutation,
    errorMessage,
    handleSaveInfo,
  };
};

export const usePasswordResetQuery = () => {
  const [errorMessage, setErrorMessage] = useState("");

  const mutation = useMutation(passwordReset, {
    useErrorBoundary: false,
    onError: (error: APIErrorResponse | any) => {
      console.log(error);
      if (error.code === "ERR_NETWORK") {
        alert("서버 오류로 서비스를 사용할 수 없습니다.");
      } else {
        if (error.status === 500) {
          alert("서버 오류입니다.");
        } else {
          alert(error?.data?.resultMsg);
        }
      }
    },
    onSuccess: (res: APIResponseData<UserResponse>) => {
      // 정상 로그인
      alert(res.resultMsg);
    },
  });

  const handlePasswordReset = useCallback((corpNo: number, metaxNo: string) => {
    setErrorMessage("");
    if (!(metaxNo && corpNo)) {
      alert("사업자번호를 입력 후 지점을 선택해주세요.");
      return;
    } else {
      mutation.mutateAsync({
        corpNo: `${corpNo}`,
        userId: metaxNo,
      });
    }
  }, []);

  return {
    ...mutation,
    handlePasswordReset,
  };
};
