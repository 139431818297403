const queryKey = {
  SIGN_IN: "SIGN_IN",
  TEST: "TEST",
  USER: "USER",
  SESSION: "SESSION",
  LAZY_TEST: "LAZY_TEST",
  MENU: "MENU",
  BOARD: "BOARD",
  BOOK: "BOOK",
  SOLD_OUT_BOOK: "SOLD_OUT_BOOK",
  NOTICE: "NOTICE",
  ORDER_STATUS: "ORDER_STATUS",
  DEAL: "DEAL",
  REFERENCE: "REFERENCE",
  MANAGER_INFO: "MANAGER_INFO",
  MANUSCRIPT_SUBMIT_STATUS: "MANUSCRIPT_SUBMIT_STATUS",
};

export const noticeQueryKey = {
  list: (
    rows: number | string,
    start: number | string | null,
    bbsTypCd?: string,
  ) => [
    queryKey.NOTICE,
    `rows=${rows}`,
    `start=${start}`,
    `bbsTypCd=${bbsTypCd}`,
  ],
  detail: (bbsSeq: number | string | null) => [queryKey.NOTICE, bbsSeq],
  all: () => [queryKey.NOTICE],
};

export const referenceQueryKey = {
  list: (
    keyword: string | null,
    rows: number | string,
    start: number | string | null,
  ) => [
    queryKey.REFERENCE,
    `keyword = ${keyword}`,
    `rows=${rows}`,
    `start=${start}`,
  ],
  detail: (dataSeq: number | string | null) => [queryKey.REFERENCE, dataSeq],
  all: () => [queryKey.REFERENCE],
};

export const manuscriptSubmitStateQueryKey = {
  list: (
    keyword: string | null,
    rows: number | string,
    start: number | string | null,
  ) => [
    queryKey.MANUSCRIPT_SUBMIT_STATUS,
    `keyword = ${keyword}`,
    `rows=${rows}`,
    `start=${start}`,
  ],
  detail: (dataSeq: number | string | null) => [
    queryKey.MANUSCRIPT_SUBMIT_STATUS,
    dataSeq,
  ],
  all: () => [queryKey.MANUSCRIPT_SUBMIT_STATUS],
};

export default queryKey;
