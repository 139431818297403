import React, { useEffect, useMemo, useRef } from "react";
import { setPrintStore, usePrintStore } from "stores/printStore";

const PrintContainer = () => {
  const printRef = useRef<HTMLDivElement>(null);
  const { PrintElement } = usePrintStore();
  useEffect(() => {
    setPrintStore((prev) => {
      return {
        ...prev,
        handlePrintButtonClick: () => {
          const windowObj = window.open(
            "",
            "Print",
            "toolbars=no, scrollbars=no, status=no, resizable=no",
          );
          windowObj?.document.writeln(printRef.current?.innerHTML as string);
          windowObj?.document.close();
          windowObj?.focus();
          windowObj?.print();
          windowObj?.close();
        },
      };
    });
  }, []);
  /* 
  ref는 React state가 아니고, 바뀐다고 재렌더링되지 않으므로 
  포함하지 않아도 ref가 변경되면 의도대로 동작하게 된다.
  */

  const style = useMemo(() => ({ display: "none" }), []);

  return (
    <div ref={printRef} style={style} id="print-container">
      {PrintElement}
    </div>
  );
};

export default PrintContainer;
