import { useSnackbar } from "notistack";
import { useCallback } from "react";

const useMessagebar = () => {
  const { enqueueSnackbar } = useSnackbar();

  const enqueue = {
    info: useCallback((message: string) => {
      enqueueSnackbar(message, { variant: "info" });
    }, []),
    success: useCallback((message: string) => {
      enqueueSnackbar(message, { variant: "success" });
    }, []),
    error: useCallback((message: string) => {
      enqueueSnackbar(message, { variant: "error" });
    }, []),
    default: useCallback((message: string) => {
      enqueueSnackbar(message, { variant: "default" });
    }, []),
    warning: useCallback((message: string) => {
      enqueueSnackbar(message, { variant: "warning" });
    }, []),
  };

  return { enqueue };
};

export default useMessagebar;
