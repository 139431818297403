import { Button } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { routes } from "config/routeConfig";
import { useModal, useNavigation, useUser, useError } from "common/hooks";
import SignOutConfirmModal from "../Modals/SignOutConfirmModal";
import ImageContainer from "../Containers/ImageContainer";
import KPCLogo from "assets/imgs/kpc-logo.png";
import usePreMutation from "common/hooks/usePreMutation";

const SignButton = () => {
  const { isSignedIn } = useUser();
  const { navigate } = useNavigation();
  const { modal } = useModal();
  const { pathname } = useLocation();

  if (isSignedIn) {
    return (
      <Button
        className="header-button"
        variant="text"
        onClick={() => modal.open(<SignOutConfirmModal />)}
      >
        로그아웃
      </Button>
    );
  } else {
    return (
      <Button
        className="header-button"
        variant="text"
        onClick={() =>
          navigate("/sign-in", {
            state: {
              callbackURL: pathname,
            },
          })
        }
      >
        로그인
      </Button>
    );
  }
};

const Header = () => {
  const { navigate } = useNavigation();
  const location = useLocation();
  const [isHeaderNeeded, setIsHeaderNeeded] = useState(true);
  const { isError } = useError();
  const { isSignedIn, user } = useUser();
  const { preMutation } = usePreMutation();

  useEffect(() => {
    routes.forEach((route) => {
      if (location.pathname === route.path) {
        setIsHeaderNeeded(route.requireHeader);
      }
    });
  }, [location]);

  const handleLogoButtonClick = useCallback(async () => {
    await preMutation();
    navigate("/");
  }, []);

  return isHeaderNeeded && !isError ? (
    <div className="header-wrapper">
      <div className="header-container">
        <ImageContainer
          src={KPCLogo}
          height={60}
          width={187}
          className="header-logo"
          onClick={handleLogoButtonClick}
        />
        {isSignedIn && (
          <Button
            variant="text"
            className="header-button"
            onClick={() => navigate("/my-page")}
          >
            <div className="user-name-text">
              {user.userName ? `${user.userName}` : ""}
            </div>
          </Button>
        )}
        {isSignedIn && <div className="menu-line">|</div>}
        <SignButton />
      </div>
    </div>
  ) : null;
};

export default Header;
