import { axiosInstance } from "config/axiosConfig";
import type { ChangePasswordRequest } from "types/user";

export async function getMenu() {
  const response = await axiosInstance.get("/api/menu/getListScmMenuInfo");
  return response.data;
}

export async function getUserInfo() {
  const response = await axiosInstance.get("/api/user/getUserInfoByToken");
  return response.data;
}

export async function changePassword(
  changePasswordRequest: ChangePasswordRequest,
) {
  const response = await axiosInstance.post(
    "/api/user/chgSecretKey",
    changePasswordRequest,
  );
  return response.data;
}

export async function checkRefreshToken() {
  const response = await axiosInstance.post(
    "user/checkRefreshToken?key=scm_rt",
    null,
    {
      baseURL: process.env.REACT_APP_BASE_URL ?? "/",
      withCredentials: true,
    },
  );
  return response.data;
}
