import React, { useEffect, useState } from "react";
import { initialMenuState } from "common/constants/initialMenuState";
import { Link, useLocation } from "react-router-dom";
import { routes } from "config/routeConfig";
import { useMenuQuery } from "common/queries/userQuery";
import type { ChildMenu, Menu, MenuResponse } from "types/menu";
import { useError } from "common/hooks";
import RightArrowIcon from "assets/imgs/right_arrow.png";
import ImageContainer from "./Containers/ImageContainer";

/* 
일단은 header가 필요한 상황에서 필요할 것이라고 가능하고 같은 로직 사용.
추후 header만 보여줄 상황이 있거나, menu만 보여줄 상황이 생긴다면
routeConfig의 route 인터페이스에 requireMenu 등의 변수를 추가하고
변형하여 사용하면 된다. 
*/
const SideMenu = () => {
  const location = useLocation();
  const [isHeaderNeeded, setIsHeaderNeeded] = useState(true);
  const { isError } = useError();
  const { data } = useMenuQuery();
  const [
    menuData,
    // setMenuData
  ] = useState(initialMenuState);

  useEffect(() => {
    if (data) {
      // console.log(data.data.menuList);
      // TODO: 백엔드 메뉴 내용이 최신화되면 initialMenuState가 아닌 받아온 메뉴로 대체
      const newMenuData: Menu[] = [];
      const menuList = data.data.menuList as MenuResponse[];
      const titleList = menuList.filter(
        // TODO: menu return type 타이핑
        (menu) => menu.menuId === menu.parentId,
      );
      titleList.forEach((titleMenu) => {
        const childMenus: ChildMenu[] = [];
        const childMenuList = menuList.filter(
          (menu) =>
            menu.parentId === titleMenu.menuId &&
            menu.menuId !== titleMenu.menuId,
        );
        childMenuList.forEach((childMenu) => {
          childMenus.push({
            title: childMenu.title,
          });
        });
        const menu: Menu = {
          title: titleMenu.title,
          childMenus,
        };
        newMenuData.push(menu);
      });
      // console.log(newMenuData);
      // setMenuData(newMenuData);
    }
  }, [data]);

  useEffect(() => {
    routes.forEach((route) => {
      if (location.pathname === route.path) {
        setIsHeaderNeeded(route.requireHeader);
      }
    });
  }, [location]);

  return isHeaderNeeded && !isError ? (
    <aside id="lnb">
      <ul>
        {menuData.map((menu) => {
          const { title, childMenus } = menu;

          return (
            <li key={title} className="parent-menu-container">
              <Link to="#" className="parent-menu">
                {title}
              </Link>
              <ul className="child-menu-container">
                {childMenus?.map((childMenu) => {
                  const { title } = childMenu;
                  const url =
                    routes.find((route) => route.title === title)?.path ?? "/";
                  let isCurrentRouteClassName = "";
                  if (location.pathname === url) {
                    isCurrentRouteClassName = "selected";
                  }
                  return (
                    <li
                      key={title}
                      className={`child-menu ${isCurrentRouteClassName}`}
                    >
                      {title === "자료실" ? (
                        <div
                          className="child-menu-link"
                          onClick={() => {
                            window.open(
                              "http://www.koreabook.or.kr/file/library.do?cate=file&page=library",
                            );
                          }}
                        >
                          {title}
                        </div>
                      ) : (
                        <Link to={url} className="child-menu-link">
                          {title}
                        </Link>
                      )}
                      <ImageContainer
                        src={RightArrowIcon}
                        height={11}
                        width={6}
                        className="right-arrow"
                      />
                    </li>
                  );
                })}
              </ul>
            </li>
          );
        })}
      </ul>
    </aside>
  ) : null;
};

export default SideMenu;
