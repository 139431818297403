import { useMutation, useQuery } from "@tanstack/react-query";
import {
  changePassword,
  checkRefreshToken,
  getMenu,
  getUserInfo,
} from "api/userApi";
import queryKey from "common/constants/queryKey";
import { useCookie, useUser } from "common/hooks";
import type { APIResponseData } from "types/api";
import type { UserResponse } from "types/user";

export const useMenuQuery = () => {
  const { isSignedIn } = useUser();
  const query = useQuery([queryKey.MENU], getMenu, {
    enabled: isSignedIn,
  });

  return { ...query };
};

export const useUserInfoQuery = () => {
  const [{ scm_at }] = useCookie(["scm_at"]);
  const { setUser } = useUser();
  const query = useQuery([queryKey.USER], getUserInfo, {
    enabled: scm_at ? true : false,
    onSuccess: (res: APIResponseData<UserResponse>) => {
      if (res.data) {
        setUser({
          modSecretKey: res.data.MOD_SECRET_KEY,
          userName: res.data.NICK_NAME,
          branchCode: res.data.USER_ID,
          headCode: res.data.tax_no,
          initSecretKeyYn: res.data.initSecretKeyYn,
          ipgeum_yn: res.data.ipgeum_yn, // 입금증 출력여부
          guild_yn: res.data.guild_yn, // 기본조합비 출력여부
          manage_yn: res.data.manage_yn, // 관리조합비 출력여부
          gong_yn: res.data.gong_yn, // 공급조합비 출력여부
        });
      }
    },
    useErrorBoundary: false,
    suspense: true,
  });
  return { ...query };
};

export const useChangePasswordQuery = () => {
  const mutation = useMutation(changePassword);
  return { ...mutation };
};

// 리프레시 토큰이 있는지 체크
export const useCheckRefreshTokenMutation = () => {
  const mutation = useMutation(checkRefreshToken);

  return { ...mutation };
};
