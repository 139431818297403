import React, { ReactNode, useEffect } from "react";
import usePreMutation from "common/hooks/usePreMutation";
import { useCheckRefreshTokenMutation } from "common/queries/userQuery";
import { useLocation } from "react-router-dom";
import { useNavigation, useUser } from "common/hooks";
import { getCookie } from "common/utils/cookieUtils";

interface WheneverContainerProps {
  children: ReactNode;
}

const WheneverContainer = ({ children }: WheneverContainerProps) => {
  const { preMutation } = usePreMutation();
  const { mutateAsync } = useCheckRefreshTokenMutation();
  const locations = useLocation();
  const { pathname } = useLocation();
  const { navigate } = useNavigation();
  const { user } = useUser();

  useEffect(() => {
    const mutation = async () => {
      const { data } = await mutateAsync();
      const scm_at = getCookie("scm_at");

      if (data === false) {
        location.href = `/sign-in`;
      } else if (data === true) {
        preMutation();
      }

      if (
        scm_at &&
        user.initSecretKeyYn === "Y" &&
        !pathname.includes("my-page")
      ) {
        alert(
          "회원님께서는 임시발급된 비밀번호를 사용중이십니다.\n\n" +
            "비밀번호를 변경하지 않으시면 악의적 사용자에 의한 개인정보 도용피해를 입으실 수 있습니다.\n\n" +
            "비밀번호 변경절차를 거치신 후 서비스 이용바랍니다.",
        );
        navigate("/my-page");
      }
    };
    mutation();
  }, [locations]);

  return <>{children}</>;
};

export default WheneverContainer;
