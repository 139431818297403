import React, { ReactNode, useEffect, useMemo } from "react";
import {
  useCookie,
  useInitWithScrollToTop,
  useLoading,
  useNavigation,
} from "common/hooks";
import AsyncBoundary from "./AsyncBoundary";
import { ErrorFallbackProps } from "common/components/ErrorFallback";
import LoadingSpinner from "common/components/LoadingSpinner";
import ErrorPage from "pages/ErrorPage";
import Header from "common/components/Header/Header";
import Footer from "common/components/Footer/Footer";
import { useUserInfoQuery } from "common/queries/userQuery";
import SideMenu from "common/components/SideMenu";
import SkeletonLoading from "../SkeletonLoading";
import { useIsFetching, useIsMutating } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";

interface PageContainerProps {
  id: string;
  children: ReactNode;
  title: string;
  parentMenu: string | null;
  description?: () => JSX.Element;
}

const PageContainer = ({
  id,
  children,
  title,
  parentMenu,
  description: Description,
}: PageContainerProps) => {
  useEffect(() => {
    const htmlTitle = document.querySelector("title");
    if (htmlTitle) {
      htmlTitle.innerHTML = title;
    }
  }, [title]);
  useInitWithScrollToTop();
  useUserInfoQuery();

  const isTitleNeeded = useMemo(() => {
    const titleExceptionList = ["로그인", "출판협동조합"];
    return !titleExceptionList.includes(title);
  }, [title]);

  const isMenuNeeded = useMemo(() => {
    const menuExceptionList = ["로그인"];
    return !menuExceptionList.includes(title);
  }, [title]);

  const style = useMemo(() => ({ width: 1200, height: 655 }), []);

  const isFetching = useIsFetching();
  const isMutating = useIsMutating();
  useLoading(isFetching > 0 || isMutating > 0);

  return (
    <div className="page-container">
      <div id={id}>
        <AsyncBoundary
          suspenseFallback={<LoadingSpinner />}
          errorFallback={({ error, resetBoundary }: ErrorFallbackProps) => (
            <ErrorPage error={error} resetBoundary={resetBoundary} />
          )}
        >
          <Header />
          <div className="content-wrapper">
            {isMenuNeeded ? (
              <>
                <SideMenu />
                <AsyncBoundary
                  suspenseFallback={
                    <div className="content">
                      <SkeletonLoading style={style} />
                    </div>
                  }
                  errorFallback={({
                    error,
                    resetBoundary,
                  }: ErrorFallbackProps) => (
                    <ErrorPage error={error} resetBoundary={resetBoundary} />
                  )}
                >
                  <div className="content">
                    {isTitleNeeded && (
                      <h2>
                        {parentMenu && (
                          <>
                            <span>{parentMenu}</span>
                            <span> | </span>
                          </>
                        )}
                        {title}
                      </h2>
                    )}
                    {Description && <Description />}
                    {children}
                  </div>
                </AsyncBoundary>
              </>
            ) : (
              <>{children}</>
            )}
          </div>
          <Footer />
        </AsyncBoundary>
      </div>
    </div>
  );
};

export default PageContainer;
