import React, { ReactNode, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigation, useUser } from "common/hooks";
import { useUserInfoQuery } from "common/queries/userQuery";
import { getCookie } from "common/utils/cookieUtils";
import { useBackgroundRefreshTokenQuery } from "common/queries/authQuery";

interface SignedInContainerProps {
  children: ReactNode;
}

const SignedInContainer = ({ children }: SignedInContainerProps) => {
  useUserInfoQuery();
  const { navigate } = useNavigation();
  const { pathname } = useLocation();
  const { user } = useUser();
  useBackgroundRefreshTokenQuery();

  useEffect(() => {
    const scm_at = getCookie("scm_at");
    //const scm_rt = getCookie("scm_rt");
    //if (!scm_at && !scm_rt && !pathname.includes("sign-in")) {
    if (!scm_at && !pathname.includes("sign-in")) {
      navigate("/sign-in");
    }
    if (
      scm_at &&
      user.initSecretKeyYn === "Y" &&
      !pathname.includes("my-page")
    ) {
      alert(
        "회원님께서는 임시발급된 비밀번호를 사용중이십니다.\n\n" +
          "비밀번호를 변경하지 않으시면 악의적 사용자에 의한 개인정보 도용피해를 입으실 수 있습니다.\n\n" +
          "비밀번호 변경절차를 거치신 후 서비스 이용바랍니다.",
      );
      navigate("/my-page");
    }
  }, [pathname]);
  return <>{children}</>;
};

export default SignedInContainer;
