import React, { useMemo } from "react";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "config/reactQueryConfig";
import LoadingContainer from "common/components/Containers/LoadingContainer";
import ModalContainer from "common/components/Containers/ModalContainer";
import PrintContainer from "common/components/Containers/PrintContainer";
import PageRoutes from "config/routeConfig";
import { SnackbarProvider } from "notistack";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { theme } from "config/themeConfig";
import { CookiesProvider } from "react-cookie";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const App = () => {
  const anchorOriginProps = useMemo(
    () => ({
      horizontal: "right" as const,
      vertical: "bottom" as const,
    }),
    [],
  );
  return (
    <CookiesProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <QueryClientProvider client={queryClient}>
          <SnackbarProvider
            anchorOrigin={anchorOriginProps}
            autoHideDuration={3000}
          >
            <LoadingContainer>
              <PageRoutes />
            </LoadingContainer>
            <ModalContainer />
            <PrintContainer />
          </SnackbarProvider>
          {/* <ReactQueryDevtools initialIsOpen={true} /> */}
        </QueryClientProvider>
      </ThemeProvider>
    </CookiesProvider>
  );
};

export default App;
