import React from "react";
import { Button } from "@mui/material";

// 페이지 단위에서 사용할 에러바운더리의 fallback
const ErrorPage = ({
  error,
  resetBoundary,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
  resetBoundary: () => void;
}) => {
  return (
    <div id="error-page">
      <div className="number">{error?.response?.status as number}</div>
      <div className="text">
        <span>Oops!</span>
        <br />
        {error.message}
      </div>
      <Button
        variant="contained"
        onClick={resetBoundary}
        className="reset-button"
      >
        새로고침
      </Button>
    </div>
  );
};

export default React.memo(ErrorPage);
