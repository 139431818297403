import type { Menu } from "types/menu";

export const initialMenuState: Menu[] = [
  {
    title: "도서관리",
    childMenus: [
      {
        title: "신간 등록",
      },
      {
        title: "도서 정보 관리",
      },
      {
        title: "반출 요청",
      },
    ],
  },
  {
    title: "주문관리",
    childMenus: [
      {
        title: "발주 관리",
      },
      {
        title: "입고 관리",
      },
      {
        title: "출고 관리",
      },
      {
        title: "반품 관리",
      },
      {
        title: "회송 관리",
      },
    ],
  },
  {
    title: "거래관리",
    childMenus: [
      {
        title: "거래원장 조회",
      },
      {
        title: "지불내역 조회",
      },
      {
        title: "계산서 조회",
      },
    ],
  },
  {
    title: "매출내역",
    childMenus: [
      {
        title: "매출 현황",
      },
    ],
  },
  {
    title: "출판사 지원",
    childMenus: [
      {
        title: "공지사항",
      },
      {
        title: "조합 담당자 정보",
      },
      // {
      //   title: "원고투고현황",
      // },
      {
        title: "자료실",
      },
    ],
  },
];
