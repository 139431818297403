import { useCallback } from "react";
import {
  useDisplayModalStore,
  setDisplayModalStore,
  initDisplayModalState,
} from "stores/displayModalStore";

const useModal = () => {
  const { open, ModalComponent } = useDisplayModalStore();
  const modal = {
    open: useCallback((modal: JSX.Element) => {
      setDisplayModalStore({ ModalComponent: modal, open: true });
    }, []),
    close: useCallback(() => {
      setDisplayModalStore(initDisplayModalState());
    }, []),
  };

  return {
    open,
    ModalComponent,
    modal,
  };
};

export default useModal;
