import React, { useMemo } from "react";
import LazyLoad from "react-lazy-load";

interface ImageContainerProps {
  src: string;
  height: number;
  width?: number;
  className?: string;
  onClick?: () => void;
}

const ImageContainer = ({
  src,
  height,
  width,
  onClick,
  className,
}: ImageContainerProps) => {
  const style = useMemo(() => {
    const returnValue = { height };
    if (width)
      Object.defineProperty(returnValue, "width", {
        value: width,
      });
    return returnValue;
  }, []);
  return (
    <div className={className ?? ""} onClick={onClick}>
      <LazyLoad height={height}>
        <img src={src} alt="image" style={style} />
      </LazyLoad>
    </div>
  );
};

export default ImageContainer;
